import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    component: () => import('@/views/main'),
    meta: { keepAlive: false },
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/main'),
    meta: { keepAlive: false },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about'),
    meta: { keepAlive: false },
  },
  {
    path: '/iot',
    name: 'iot',
    component: () => import('@/views/iot'),
    meta: { keepAlive: false },
  },
  {
    path: '/website',
    name: 'website',
    component: () => import('@/views/website'),
    meta: { keepAlive: false },
  },
  {
    path: '/consult',
    name: 'consult',
    component: () => import('@/views/consult'),
    meta: { keepAlive: false },
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('@/views/case'),
    meta: { keepAlive: true },
  },
  {
    path: '/app-case',
    name: 'app-case',
    component: () => import('@/views/app-case'),
    meta: { keepAlive: false },
  },
  {
    path: '/my-mini',
    name: 'my-mini',
    component: () => import('@/views/my-mini'),
    meta: { keepAlive: false },
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('@/views/app'),
    meta: { keepAlive: false },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news'),
    meta: { keepAlive: false },
  },
]

const createRouter = () =>
  new Router({
    routes: constantRoutes,
  })

const router = createRouter()

export default router
