<template>
  <div class="nav-bar">
    <div class="nav-box">
      <el-row class="container">
        <el-col :offset="2" :span="3">
          <router-link :to="'main'" class="link-address"><img class="logo" src="../../assets/img/logo.png" @click="goto('/')" alt="" /></router-link>
        </el-col>
        <el-col :span="2" v-for="(i, index) in list" :key="i.id">
          <div class="link-box" :class="(index+1) === list.length?'link-box-last': ''"><router-link :to="i.remark" class="link-address">{{i.label}}</router-link></div>
        </el-col>
        <el-col :span="3" style="text-align: left;">
          <div class="phone">
            <i class="icon-phone">18039469452</i>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getBanner, getSwiper } from '@/api/lykj'
export default {
  name: "navBarComponent",
  data() {
    return {
      list: [],
      logo: ''
    };
  },
  mounted() {
    getBanner({}).then((data)=> {
      this.list = data
    })
    getSwiper({ bottowType: 8 }).then((res)=> {
      this.logo = res[0].imgUrl
    })
  },
  methods: {
    goto(path) {
      this.$router.push(path)
    },
  }
};
</script>
<style lang="scss" scoped>
.nav-bar {
  width: 100%;
  height: 80px;
  background-color: #181A1A;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 999;

  .nav-box {
    width: 73%;
    margin: 0 auto;

    .container {
      width: 100%;
      display: inline-block;
      height: 80px;
      line-height: 80px;
      padding: 0 20px;
      text-align: center;
      
      .link-box{
          border-right: 1px solid #fefefe;
          
          .link-address{
              //font-size: 16px;
          }
      }

      .logo {
        display: inline-block;
        height: 50px;
        margin-top: 15px;
        width: 100%;
      }

      .logo-content {
        display: inline-block;
        height: 100%;
        margin-left: 20px;
        background-image: url('../../assets/img/logo-content.png');
        background-size: 100% 25%;
        background-repeat: no-repeat;
        background-position: center;
      } 

      .phone {
        display: inline-block;
        height: 80px;
        line-height: 80px;

        .icon-phone {
          display: inline-block;
          width: 1.4rem;
          height: 80px;
          background-image: url('../../assets/img/nav_phone.png');
          background-size: 1.4rem 1.4rem;
          background-repeat: no-repeat;
          background-position: center;
          padding-left: 28px;
        }
      }
    }
  }
}
</style>