<template>
  <div id="app">
    <div class="toTop">
      <!-- 导航 -->
      <nav-bar-vue></nav-bar-vue>
      <transition name="fade">
        <keep-alive :include="cashViews">
          <router-view></router-view>
        </keep-alive>
      </transition>
      <!-- 页尾 -->
      <v-footer></v-footer>
    </div>
    <el-backtop target=".toTop">
      <div>
        <div>回到顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import vFooter from '@/components/v-footer'
import navBarVue from '@/components/navBar'
import { isMobile } from '@/utils/getInfo'
let isMobi = isMobile()
// console.log(this.isMobi, "this.isMobi"); //可以打印出来看看判断是否正确
if (isMobi) {
  //假如是移动端，进行一些操作
  window.location.href = 'https://m.ilyixy.com'
}

export default {
  name: 'App',
  components: {
    vFooter,
    navBarVue,
  },
  data() {
    return {
      // 要缓存的组件
      cashViews: ['listCase'],
    }
  },
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}

.toTop {
  height: 100%;
  overflow: scroll;
  overflow-x: auto;
}
.el-backtop {
  width: 6em !important;
  border-radius: 1em !important;
}
</style>
