import { Message } from 'element-ui'
import http from '@/utils/httpRequest'

function get(url, datas) {
    return http({
        url: 'dev-api/api/' + url,
        method: 'get',
        params: datas
    })
}
function post(url, datas) {
    return http({
        url: 'dev-api/api/' + url,
        method: 'post',
        data: datas
    })
}
function post2(url, datas) {
    return http({
        url: 'dev-api/api/' + url,
        method: 'post',
        params: datas
    })
}

// 获取数据
// 1友情链接，2首页banner
export async function getlinks(bottowType) {
    const { data } = await get('link/list', bottowType);
    if (data.code === 200) {
        return data.rows;
    } else {
        Message.error(data.msg);
        return undefined;
    }
}

// 获取公司展示轮播图
export async function getSwiperBtn() {
    const { data } = await get('link/imgList');
    if (data.code === 200) {
        return data.rows;
    } else {
        Message.error(data.msg);
        return undefined;
    }
}

// 获取首页轮播图
export async function getSwiper(bottowType) {
    const { data } = await get('link/list', bottowType);
    if (data.code === 200) {
        return data.rows;
    } else {
        Message.error(data.msg);
        return undefined;
    }
}

// 获取底部信息
export async function getFooter() {
    const { data } = await get('footer/' + 1);
    if (data.code === 200) {
        return data.data;
    } else {
        Message.error(data.msg);
        return undefined;
    }
}

// 获取新闻列表
export async function getNews(datas) {
    const { data } = await get('interview/list', datas);
    if (data.code === 200) {
        return data;
    } else {
        Message.error(data.msg);
        return undefined;
    }
}

// 提交需求
export async function add(datas) {
    const { data } = await post('issue/getInfoAdd', datas);
    if (data.code === 200) {
        Message.success(data.msg);
    } else {
        Message.error(data.msg);
        return undefined;
    }
}

// 获取banner图
export async function getBanner(datas) {
    const { data } = await post('article/treeselect', datas);
    if (data.code === 200) {
        return data.data;
    } else {
        Message.error(data.msg);
        return undefined;
    }
}

// 获取主类型
export async function getMainTypes() {
    const { data } = await post('primitives/seleteNewsTypeList');
    if (data.code === 200) {
        return data.data;
    } else {
        Message.error(data.msg);
        return [];
    }
}

// 获取子类型
export async function getSubTypes(datas) {
    const { data } = await get('primitives/getInfoPrimId', datas);
    if (data.code === 200) {
        return data.data ? data.data.subtypeList : [];
    } else {
        Message.error(data.msg);
        return [];
    }
}

// 获取案例列表
export async function getCaseArr(datas) {
    const { data } = await get('article/newsByChannelIdListType', datas);
    if (data.code === 200) {
        return data;
    } else {
        Message.error(data.msg);
        return [];
    }
}

// 获取案例详情
export async function getCaseDetail(datas) {
    const { data } = await get('article/newsByChannelIdDetails', datas);
    if (data.code === 200) {
        return data.data;
    } else {
        Message.error(data.msg);
        return [];
    }
}

// 获取下一条案例
export async function getCaseNext(datas) {
    const { data } = await post2('article/detailNext', datas);
    if (data.code === 200) {
        return data.data;
    } else {
        Message.error(data.msg);
        return [];
    }
}

// 获取上一条案例
export async function getCasePrev(datas) {
    const { data } = await post2('article/detailUp', datas);
    if (data.code === 200) {
        return data.data;
    } else {
        Message.error(data.msg);
        return [];
    }
}

// 获取新闻详情
export async function getNewsDetail(datas) {
    const { data } = await get('interview/getInfo', datas);
    if (data.code === 200) {
        return data.data;
    } else {
        Message.error(data.msg);
        return [];
    }
}

// 获取下一条新闻
export async function getNewsNext(datas) {
    const { data } = await post2('interview/detailNext', datas);
    if (data.code === 200) {
        return data.data;
    } else {
        Message.error(data.msg);
        return [];
    }
}

// 获取上一条新闻
export async function getNewsPrev(datas) {
    const { data } = await post2('interview/detailUp', datas);
    if (data.code === 200) {
        return data.data;
    } else {
        Message.error(data.msg);
        return [];
    }
}
