<template>
  <div class="footer">
    <div class="footer-box">
      <div class="friends">
        <div class="friends-label">友情链接:</div>
        <ul class="friends-item">
          <li v-for="item in list" :key="item.linkId" @click="toHref(item.linkUrl)"><img :src="item.imgUrl" alt="" /></li>
        </ul>
      </div>
      <el-divider></el-divider>
      <el-row class="footer-content">
        <el-col :span="7">
          <div class="logo">
            <router-link :to="'/'">
              <img :src="footerInfo.logo" alt="" class="profile-logo" />
            </router-link>
          </div>
          <router-link :to="'/'" class="link-address">
            <div class="footer-content-info">{{ footerInfo.name }}</div>
          </router-link>
          <div class="footer-content-info">
            <div class="icon-phone"></div>
            <div class="tel">{{ footerInfo.conac }}</div>
          </div>
          <div class="footer-content-info">邮箱: {{ footerInfo.keyword }}</div>
          <div class="footer-content-info">
            地址: <a href="https://seo.chinaz.com/www.ilyixy.com" class="web" target="_blank">{{ footerInfo.errorInfo }}</a>
          </div>
        </el-col>
        <el-col :span="1"><div class="split"></div></el-col>
        <el-col :span="7">
          <el-row class="footer-center">
            <el-col :span="12">
              <div class="footer-center-title">产品体系</div>
              <div class="footer-center-item" v-for="i in cpList" :key="i.id">
                <router-link :to="i.remark" class="link-address">{{i.label}}</router-link>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="footer-center-title">服务体系</div>
              <div class="footer-center-item" v-for="i in serveList" :key="i.id">
                <router-link :to="i.remark" class="link-address">{{i.label}}</router-link>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="1"><div class="split"></div></el-col>
        <el-col :span="7">
          <div class="footer-right">
            <img
              src="../../assets/img/code.png"
              class="footer-right-code"
              alt=""
            />
            <img
              src="../../assets/img/codefzg.png"
              class="footer-right-code"
              alt=""
            />
            <div>关注我们</div>
          </div>
        </el-col>
      </el-row>
      <div class="footer-end">{{ footerInfo.websiteDescribe }} <a href="https://beian.miit.gov.cn" class="web" target="_blank">{{ footerInfo.footer }}</a></div>
    </div>
  </div>
</template>

<script>
import { getlinks, getFooter, getBanner } from '@/api/lykj'
export default {
  name: "v-swiper",
  data() {
    return {
      list: [],
      footerInfo: {},
      cpList: [],
      serveList: []
    };
  },
  mounted() {
    getlinks({bottowType: 1}).then((res)=> {
      this.list = res
    }) 
    getFooter().then((res)=> {
      this.footerInfo = res
    })
    getBanner({isNav: "1"}).then((data)=> {
      this.cpList = data
    })
    getBanner({isNav: "2"}).then((data)=> {
      this.serveList = data
    })
  },
  methods: {
    toHref(item){
      window.location.href = item
    }
  },
};
</script>
<style lang="scss" scoped>
.footer {
  background-color: #262830;

  .footer-box {
    width: 60%;
    margin: 0 auto;

    .el-divider--horizontal {
      margin: 0;
      background-color: rgba(255, 255, 255, 0.2);
    }

    .friends {
      display: inline-block;
      height: 80px;
      line-height: 80px;

      .friends-label {
        float: left;
        color: #fff;
      }

      .friends-item {
        display: inline-block;
        list-style-type: none;

        li {
          float: left;
          margin-left: 20px;

          img {
            vertical-align: middle;
            height: 40px;
          }
        }
      }
    }

    .friends::after {
      content: '';
      display: block;
      clear: both;
    }

    .footer-content {
      padding: 20px 0;
      color: #fff;

      .logo {
        width: 120px;
        height: 50px;
        margin-bottom: 10px;
      }

      .split {
        width: 1px;
        height: 172px;
        background-image: url("../../assets/img/split.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
      }

      .footer-content-info {
        height: 2rem;
        line-height: 2rem;

        .icon-phone {
          display: inline-block;
          width: 1rem;
          height: 1rem;
          background-image: url("../../assets/img/phone.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;
        }

        .tel {
          display: inline-block;
          padding-left: 1rem;
        }
      }

      .footer-center {
        .footer-center-title {
          font-size: 18px;
          height: 3rem;
          line-height: 3rem;
        }

        .footer-center-item {
          height: 2rem;
          line-height: 2rem;
        }
      }

      .footer-right {
        text-align: center;

        .footer-right-code {
          margin: 10px;
          width: 120px;
          height: 120px;
          vertical-align: middle;
        }
      }
    }

    .footer-end {
      text-align: center;
      color: #fff;
      padding: 20px 0;
    }
    
    .web {
      color: #fff;
      text-decoration: none;
    }

    .web:active {
      color: rgb(188, 181, 216);
    }
  }
}
</style>