import axios from 'axios'
import qs from 'qs'
import merge from 'lodash/merge'

// baseURL: 'http://ilyixy.com:8082/',
const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  baseURL: 'https://www.ilyixy.com/',
  // baseURL: 'http://192.168.0.101 local.ly.pc.ilyixy.com',
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

// http.baseurl = 'http://ilyixy.com:8082'
http.baseurl = 'https://www.ilyixy.com'
// http.baseurl = 'http://192.168.0.101 local.ly.pc.ilyixy.com'
/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  return response
}, error => {
  return Promise.reject(error)
})

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  return openDefultParams ? merge(params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  data = openDefultdata ? merge(data) : data
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

export default http
